<!--
 * @Author: 智客云网络科技
 * @Date: 2021-12-09 16:50:52
 * @LastEditors: 阡陌OvO
 * @LastEditTime: 2022-02-16 16:11:10
 * @Description: 创建 修改 申报词条
 * @FilePath: \src\views\App\Iemma\NUDeclareCommodityItem.vue
-->
<template>
  <el-dialog
    v-drag2anywhere
    :title="title"
    :visible.sync="isShow"
    width="950px"
    top="10vh"
    append-to-body
    destroy-on-close
    :close-on-click-modal="false"
    @close="_HandleClose()"
    custom-class="full-dialog"
  >
    <div class="vue-box">
      <el-card
        shadow="never"
        v-loading="loading"
        element-loading-text="拼命加载中..."
      >
        <el-form :model="m" :inline="true" ref="m" label-width="110px">
          <el-form-item label="词条分类：" style="width: 45%">
            <el-select size="small" v-model="m.kcoc_id">
              <el-option
                v-for="item in CommodityClassList"
                :key="item.kcoc_id"
                :label="item.name"
                :value="item.kcoc_id"
              >
              </el-option>
            </el-select>
            <el-tooltip
              class="item"
              effect="dark"
              content="刷新分类列表"
              placement="top-start"
            >
              <el-button
                size="small"
                icon="el-icon-refresh"
                @click="_GetDeclareCommodityClassList()"
              ></el-button>
            </el-tooltip>
          </el-form-item>

          <el-form-item label="清关类型：" style="width: 45%">
            <el-select size="small" v-model="m.cc_type">
              <el-option label="目录清关" :value="200010"> </el-option>
              <el-option label="简易清关" :value="200011"> </el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="词条名称：" style="width: 45%">
            <el-input
              size="small"
              v-model="m.name"
              suffix-icon="xxxx"
            ></el-input>
          </el-form-item>

          <el-form-item label="申报名称：" style="width: 45%">
            <el-input
              size="small"
              v-model="m.declare_name"
              suffix-icon="xxxx"
            ></el-input>
          </el-form-item>

          <el-form-item label="SKU编码：" style="width: 45%">
            <el-input
              size="small"
              v-model="m.sku_code"
              suffix-icon="xxxx"
            ></el-input>
          </el-form-item>

          <el-form-item label="HS编码：" style="width: 45%">
            <el-input
              size="small"
              v-model="m.hs_code"
              suffix-icon="xxxx"
            ></el-input>
          </el-form-item>

          <el-form-item label="参考价格：" style="width: 45%">
            <el-input
              size="small"
              v-model="m.price"
              class="linp"
              suffix-icon="xxxx"
            >
              <template slot="prepend">$</template>
            </el-input>
          </el-form-item>

          <el-form-item label="参考重量：" style="width: 45%">
            <el-input
              size="small"
              v-model="m.weight"
              class="rinp"
              suffix-icon="xxxx"
            >
              <template slot="append">g</template>
            </el-input>
          </el-form-item>

          <el-form-item label="可食用：" v-if="id !== 3" style="width: 30%">
            <el-switch
              size="small"
              v-model="m.edible"
              :active-value="1"
              :inactive-value="0"
            ></el-switch>
          </el-form-item>

          <el-form-item label="自动价格：" v-if="id !== 3" style="width: 30%">
            <el-switch
              size="small"
              v-model="m.auto_price"
              :active-value="1"
              :inactive-value="0"
            ></el-switch>
          </el-form-item>

          <el-form-item label="词条状态：" v-if="id !== 3" style="width: 30%">
            <el-switch
              size="small"
              v-model="m.status"
              :active-value="1"
              :inactive-value="0"
            ></el-switch>
          </el-form-item>

          <div class="from-notes-box">
            <el-form-item
              label="描述："
              prop="notes"
              style="width: 90%; display: block"
            >
              <el-input
                size="small"
                v-model="m.notes"
                type="textarea"
                :rows="5"
              ></el-input>
            </el-form-item>
          </div>
        </el-form>

        <el-divider></el-divider>

        <el-button
          size="small"
          type="primary"
          icon="el-icon-plus"
          @click="_CreateDeclareCommodityItems()"
          v-if="id == 0"
          >提交</el-button
        >
        <el-button
          size="small"
          type="primary"
          icon="el-icon-check"
          @click="_UpdateDeclareCommodityItems()"
          v-else
          >保存</el-button
        >
        <el-button size="small" icon="el-icon-close" @click="isShow = false"
          >取消</el-button
        >
      </el-card>
    </div>
  </el-dialog>
</template>

<script>
import {
  createDeclareCommodityItem,
  updateDeclareCommodityItem,
  getDeclareCommodityItemInfoById,
  getCommodityClassList,
} from "./api"; //页面专有接口
export default {
  data() {
    return {
      m: {
        kcoc_id: "",
        name: "",
        declare_name: "",
        status: 1,
        sku_code: "",
        hs_code: "",
        edible: 0,
        price: "",
        weight: "",
        auto_price: 1,
        cc_type: 200010,
        notes: "",
      },
      id: 0, //
      title: "", //弹窗标题
      isShow: false, //显示控制
      loading: true, //加载控制
      CommodityClassList: [], //分类列表
    };
  },
  methods: {
    // 打开
    open(id) {
      this.isShow = true; //显示组件
      this.id = id; //重写ID
      this._GetDeclareCommodityClassList(); //获取分类列表
      this.$nextTick(() => {
        if (id === 0) {
          //新建
          this.title = "添加申报词条";
          this.loading = false;
        } else {
          //修改
          this._GetWarehouseInfo(id);
          this.title = "修改申报词条";
        }
      });
    },

    //添加申报词条
    _CreateDeclareCommodityItems() {
      var m = this.m;
      createDeclareCommodityItem({
        kcoc_id: m.kcoc_id,
        name: m.name,
        declare_name: m.declare_name,
        status: m.status,
        sku_code: m.sku_code,
        hs_code: m.hs_code,
        edible: m.edible,
        price: m.price,
        weight: m.weight,
        auto_price: m.auto_price,
        cc_type: m.cc_type,
        notes: m.notes,
      })
        .then((res) => {
          this.$utils.success(res.err_msg);
          this.isShow = false;
        })
        .catch((err) => {
          this.$utils.error(err.err_msg);
        });
    },

    //修改申报词条
    _UpdateDeclareCommodityItems() {
      var m = this.m;
      updateDeclareCommodityItem({
        kdei_id: this.id,
        kcoc_id: m.kcoc_id,
        name: m.name,
        declare_name: m.declare_name,
        status: m.status,
        sku_code: m.sku_code,
        hs_code: m.hs_code,
        edible: m.edible,
        price: m.price,
        weight: m.weight,
        auto_price: m.auto_price,
        cc_type: m.cc_type,
        notes: m.notes,
      })
        .then((res) => {
          this.$utils.success(res.err_msg);
          this.isShow = false;
        })
        .catch((err) => {
          this.$utils.error(err.err_msg);
        });
    },

    //获取申报词条信息
    _GetWarehouseInfo(kdei_id) {
      getDeclareCommodityItemInfoById({ kdei_id })
        .then((res) => {
          let m = this.m;
          let d = res.data;
          m.kcoc_id = d.kcoc_id;
          m.name = d.name;
          m.declare_name = d.declare_name;
          m.status = d.status;
          m.sku_code = d.sku_code;
          m.hs_code = d.hs_code;
          m.edible = d.edible;
          m.price = d.price !== 0 ? d.price : "";
          m.weight = d.weight !== 0 ? d.weight : "";
          m.auto_price = d.auto_price;
          m.cc_type = d.cc_type;
          m.notes = d.notes;
          this.loading = false;
        })
        .catch((err) => {
          this.$utils.error(err.err_msg);
        });
    },

    //获取分类列表
    _GetDeclareCommodityClassList() {
      getCommodityClassList()
        .then((res) => {
          this.CommodityClassList = res.data;
        })
        .catch((err) => {
          this.CommodityClassList = [];
          if (err.code === 180003) {
            this.$utils.msg(err.err_msg);
          }
          this.$utils.error(err.err_msg);
        });
    },

    //关闭
    _HandleClose() {
      Object.assign(this.$data, this.$options.data());
      this.$parent._Load(); //通知父组件刷新数据
    },
  },
};
</script>
<style>
.linp {
  width: calc(100% - 48px);
}

.rinp {
  width: calc(100% - 55px);
}
</style>
